/* .login_register_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
} */
.login_register_logo {
  margin-top: 2rem;
}

.input_boxes_login_register {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 100%;
  /* New changes */
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
}

.input_boxes_login_register input {
  background: transparent;
  border: 1px solid #D1D5DB;
  outline: none;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: left;
  font-size: 1.2rem;
  width: 100%;
}

/* input_boxes_login_register input:active .input_boxes_login_register {
  border: 1px solid #2148c0;
} */

/* .login_register_input_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 5px;
  align-items: flex-start;
  margin-right: 6.25rem;
  width: 27rem;
  opacity: 0.8;
  justify-content: center;
  padding: 1.5rem;
  align-self: flex-end;
  align-items: center;
  margin: auto;
  margin-right: 2rem;
} */

.login_register_btn.MuiButton-root {
  background-color: #6366F1 !important;
  color: #fff;
  border-radius: 8px;
  padding: 0.7rem 0;
  text-transform: capitalize;
  width: 100%;
}

.google_sign_in_btn {
  display: flex;
  justify-content: center;
  outline: none;
  border: 2px solid rgb(239, 239, 239);
  background: #ffffff;
  align-items: center;
  padding: 0.3rem 0;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
}

.margin-forget-password {
  margin-top: 2rem;
}

.login_logo {
  /* width: 8rem;
  height: 8rem; */
  /* margin-top: 1rem; */
  display: flex;
  justify-content: center;
  /* New changes */
  width: 5rem;
  height: 5rem;
}

.login_logo img {
  width: 8rem;
  height: 4rem;
}

.in-forget {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 0 0.6rem;
}

.forgot_password_text {
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .login_register_input_container {
    margin-right: 0;
  }
}

@media screen and (max-width: 460px) {
  .login_register_input_container {
    width: 90%;
  }

  .forgot_password_text {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 370px) {

  /* .login_register_input_container {
    width: 19rem;
  } */
  .in-forget {
    display: block;
  }

  .in-forget p {
    display: flex;
    justify-content: center;
  }
}

/* for the phone number library */
.PhoneInputCountry {
  margin-left: 13px;
}

.PhoneInput {
  width: 100%;
}

.forget-password-title.MuiTypography-root {
  font-size: 16px;
  font-weight: 700;
}

.forget-password-subtitle.MuiTypography-root {
  font-size: 14px;
}

.forget-form {
  height: min-content;
}

/* ----------------------------------------------------------------------------------------- */

/* my styles */

.mainContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  padding-bottom: 1rem;
  gap: 3.5rem;
  height: 100%;
  background: #ffffff;
}

.upperSection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
}


.lowerSection {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.rightSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  order: 2;
}

.leftSection {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  order: 1;
}

.login_register_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 1.25rem;
  background-color: #ffffff;
  border: 1px solid #D1D5DB;
  margin: 0px 0px auto auto;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.testimonial,
.bottomSection-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.author-details {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: #666;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  width: 100%;
}

.feature-list li {
  margin-bottom: 0.6rem;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.get-started-btn {
  background-color: #6366F1 !important;
  color: white !important;
  border-radius: 8px;
  margin-top: 0.5rem;
}

@media screen and (max-width: 768px) {
  .mainContainer {
    padding: 1rem;
  }

  .login_register_input_container {
    margin-right: auto;
  }

  .rightSection {
    order: 1;
  }

  .leftSection {
    order: 2;
  }
}

@media screen and (min-width: 1024px) {
  .upperSection {
    grid-template-columns: 2fr 1fr;
    gap: 4rem;
  }

}